import React, {useEffect, useState} from 'react';
import cn from "classnames";
import {SHOW_SCROLLTOP_OFFSET} from "utils/config";

const ScrollTopButton = props => {

	const [active, setActive] = useState(window.pageYOffset >= SHOW_SCROLLTOP_OFFSET);
	const onScroll = (e) => {
		// let scrollTop = e.srcElement.body.scrollTop;
		// console.log('onScroll');
		// console.log(e);
		// console.log(window.pageYOffset);

		setActive(window.pageYOffset >= SHOW_SCROLLTOP_OFFSET);
	};

	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		}
	}, []);



	return (
		<div
			className={cn("scroll-top-btn", {active: active})}
			onClick={() => {
				window.scrollTo({top: 0, behavior: 'smooth'});
			}}
		>
			<i className={"fa fa-chevron-up"}></i>
		</div>
	)

};

export default ScrollTopButton;